import React, { useEffect, useState } from 'react';
import SideBar from 'components/SideBar';
import CardViewer from 'components/CardViewer';
import CardActions from 'components/CardActions';
import content from '../data.json';
import About from 'components/About';
import { useParams } from 'react-router-dom';

export default function Home() {
  const params = useParams();
  const { id } = params;
  const [selectedItem, setSelectedItem] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [width, setWidth] = useState(0);
  //const [height, setHeight] = useState(0);
  const breakPoint = 500;

  useEffect(() => {
    //setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    window.addEventListener(
      'resize',
      () => {
        //setHeight(window.innerHeight);
        setWidth(window.innerWidth);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (width < breakPoint) {
      setMinimized(true);
    } else {
      setMinimized(false);
    }
  }, [width]);

  useEffect(() => {
    setSelectedItem(content.find((item) => item.id === id));
  }, [id]);

  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        <SideBar
          minimized={minimized}
          setMinimized={setMinimized}
          onSelect={(item) => {
            if (width < breakPoint) setMinimized(true);
            setSelectedItem(item);
          }}
          content={content}
          selectedItem={selectedItem}
        />
      </div>
      <div style={styles.content}>
        <CardActions
          minimized={minimized}
          setMinimized={setMinimized}
          item={selectedItem}
        />
        {(selectedItem && (
          <CardViewer
            minimized={minimized}
            setMinimized={setMinimized}
            item={selectedItem}
          />
        )) || <About minimized={minimized} />}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    overflow: 'clip',
    margin: 0,
    padding: 0
  },
  sidebar: {
    zIndex: 1,
    height: '100vh',
    background: '#202020',
    boxShadow: '-9px 13px 18px 7px black'
  },
  content: {
    position: 'relative',
    flexGrow: '1',
    height: '100%',
    width: '100%'
  }
};
