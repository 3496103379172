import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
const assetUrl = 'https://assets.intrepidpixel.org/twitterpostcards';

export default function SideBar({
  content,
  selectedItem,
  minimized,
  setMinimized,
  onSelect
}) {
  const [filter, setFilter] = useState(null);
  const [cardList, setCardList] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (filter) {
      setCardList(content.filter((item) => item.caption.includes(filter)));
    } else {
      setCardList(content);
    }
  }, [content, filter]);

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 5,
        display: minimized ? 'none' : 'block'
      }}
    >
      <div style={styles.navHeader}>
        <Navigation
          minimized={minimized}
          setMinimized={setMinimized}
          setFilter={setFilter}
        />
      </div>
      <div style={styles.cardList}>
        {cardList?.length === 0 && (
          <div
            style={{
              color: 'white',
              fontWeight: 900,
              margin: 'auto',
              textAlign: 'center',
              marginTop: '2rem',
              opacity: 0.6
            }}
          >
            no matches found
          </div>
        )}
        {cardList?.map((item, idx) => (
          <div
            key={idx}
            style={{
              ...styles.card,
              border:
                item === selectedItem
                  ? '3px solid #8900f2'
                  : '0px solid #cccccc'
            }}
          >
            <div
              onClick={() => {
                navigate(`/${item.id}`);
                if (typeof onSelect === 'function') onSelect();
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center'
              }}
            >
              <div style={{ margin: 'auto', height: '2rem' }}>
                <img
                  draggable={false}
                  alt={item.title}
                  style={styles.cardImg}
                  src={`${assetUrl}/${item.id}/thumb.png`}
                />
              </div>
              <div style={{ margin: 'auto' }}>{item.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  icon: {
    color: 'white',
    size: '2rem'
  },
  navHeader: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '13rem',
    padding: '1rem',
    background: '#202020',
    boxShadow: 'black -20px 1px 20px 3px'
  },
  navHeaderCompact: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '2rem',
    padding: '1rem',
    background: 'rgba(0,0,0,.2)'
  },
  cardList: {
    overflow: 'scroll',
    marginTop: '8.5rem',
    width: '15rem',
    height: 'calc(100vh - 7.5rem)'
  },
  card: {
    display: 'block',
    margin: '1rem',
    borderRadius: '0.3rem',
    overflow: 'clip',
    userSelect: 'none',
    boxSizing: 'border-box',
    height: '8rem'
  },
  cardImg: { objectFit: 'fill', width: '13rem' }
};
