import React from 'react';

const About = ({ minimized = false }) => {
  return (
    <div style={styles.wrapper}>
      <div
        style={{
          ...styles.container,
          marginTop: minimized ? '3rem' : 0,
          padding: '.3rem'
        }}
      >
        <div style={{ ...styles.header, marginTop: '1rem' }}>
          Wish You Were Here
        </div>
        <div>
          A <a href="http://feralresearch.org">Feral Research</a>/
          <a href="http://intrepidpixel.org">Intrepid Pixel</a> project.
        </div>
        <div style={{ flexGrow: 1 }} />
        <div
          style={{
            border: '2px solid white',
            borderRadius: '7px',
            margin: '2rem 0'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: minimized ? 'column' : 'row',
              alignItems: 'center'
            }}
          >
            <div>
              <img
                alt="3D Icon"
                style={{ width: '10rem', margin: '1rem 1rem 0 1rem ' }}
                src="assets/img/7009615.png"
              />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div
              style={{
                fontWeight: 900,
                fontFamily: 'AuthenticSans',
                lineHeight: '1.2rem',
                fontSize: '1.2rem',
                margin: '1rem 0 1rem 0',
                textAlign: 'center'
              }}
            >
              Use mouse or fingers to
              <br /> scale, move and rotate cards
            </div>
            <div style={{ flexGrow: 1 }} />
          </div>
        </div>
        <div>
          A collection of fictional postcards derived by running people's
          twitter bios through{' '}
          <a href="https://en.wikipedia.org/wiki/Stable_Diffusion">
            Stable Diffusion
          </a>{' '}
          prefaced with the text "postcard of..." The backs of the cards are
          stamped and franked using similarly generated stamps and marks. These
          are kitchsy evidence of places that don't exist, sent from nowhere to
          nowhere, bearing marks of travel through a system that never touched
          them.
        </div>
        <div style={{ marginTop: '1rem' }}>
          I never meant for this to me a memorial, but I created these a month
          or so before the network I was a part of for almost 15 years was
          destroyed. This is likely the last Twitter project I'll ever produce,
          evidence of a time and place that nobody remembers and nobody has
          visited but remains very real in my own head. Wish you were here.
        </div>
        <div style={styles.header}>Code and Credits</div>
        <div>
          Many thanks to all on Twitter who participated by requesting a card.
          You may recognize your own bios here - I've done my best to generally
          remove any personally identifying information but of course you can
          probably figure out who is who - get in touch and I'll take down your
          card if you prefer it not to be online.{' '}
          <div style={{ marginTop: '1rem' }}>
            Cards, stamps and marks were all generated using Stable Diffusion,
            specifically{' '}
            <a href="https://github.com/AUTOMATIC1111/stable-diffusion-webui">
              this project
            </a>{' '}
            which provides a nice self-contained web interface which makes it
            ideal for play like this.
          </div>
          <div style={{ marginTop: '1rem' }}>
            360' icon above created by modifying{' '}
            <a
              href="https://www.flaticon.com/free-icons/3d-modeling"
              title="3d modeling icons"
            >
              {' '}
              icons from Freepik - Flaticon
            </a>{' '}
          </div>
        </div>
        <div style={{ minHeight: '10rem' }}></div>
        <div style={{ flexGrow: 1 }} />
      </div>
    </div>
  );
};
export default About;

const styles = {
  wrapper: {
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflow: 'scroll'
  },
  container: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    width: '600px',
    maxWidth: '90vw',
    margin: 'auto',
    fontSize: '1rem',
    lineHeight: '1.5rem'
  },
  header: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
    fontFamily: 'AuthenticSans',
    fontWeight: 900,
    textTransform: 'uppercase',
    margin: '2rem 0 .2rem 0'
  }
};
