import React from 'react';
import { TbLayoutSidebarRightExpand, TbInfoCircle } from 'react-icons/tb';
import { AiOutlineSearch } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import { useNavigate } from 'react-router-dom';

const Navigation = ({ setFilter, minimized, setMinimized }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div>
        <IconContext.Provider value={styles.icon}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div style={{ marginRight: '.2rem' }}>
              {/* <div
              onClick={()=>{document.location.href = "http://intrepidpixel.org"}}
                style={{
                  background: '#8900f2',
                  borderRadius: '23px',
                  height: '23px',
                  width: '23px',
                  border: '2px solid white'
                }}
              />
              */}
            </div>
            <div style={{ position: 'relative', top: '.13rem' }}>
              <TbInfoCircle onClick={() => navigate(`/`)} />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ position: 'relative', top: '.13rem' }}>
              <TbLayoutSidebarRightExpand
                onClick={() => setMinimized(!minimized)}
              />
            </div>
          </div>
        </IconContext.Provider>
      </div>
      <div style={{ position: 'relative' }}>
        <input
          style={styles.input}
          onChange={(e) => setFilter(e.target.value)}
          type="text"
          placeholder="Search..."
        />
        <div style={{ position: 'absolute', right: '.4rem', top: '1.6rem' }}>
          <IconContext.Provider
            value={{
              color: 'grey',
              size: '2rem'
            }}
          >
            <AiOutlineSearch />
          </IconContext.Provider>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Navigation;

const styles = {
  icon: {
    color: 'white',
    size: '2rem'
  },
  input: {
    appearance: 'none',
    color: '#8900f2',
    background: '#0000003b',
    margin: '1rem 0 0 0',
    width: 'calc(100% - 1rem)',
    height: '2rem',
    padding: '0.5rem',
    fontSize: '1rem',
    fontWeight: 900,
    borderRadius: '0.3rem',
    border: 0
  }
};
