import * as THREE from 'three';
import React, { useState, useRef, useEffect } from 'react';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
const assetUrl = 'https://assets.intrepidpixel.org/twitterpostcards';

export default function CardViewer({ item, minimized }) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(document.getElementById('container')?.offsetHeight);
    setWidth(document.getElementById('container')?.offsetWidth);
    window.addEventListener(
      'resize',
      () => {
        setHeight(document.getElementById('container')?.offsetHeight);
        setWidth(document.getElementById('container')?.offsetWidth);
      },
      false
    );
  }, []);

  useEffect(() => {
    setHeight(document.getElementById('container')?.offsetHeight);
    setWidth(document.getElementById('container')?.offsetWidth);
  }, [minimized]);

  return (
    <div id={'container'} style={styles.container}>
      <div style={{ margin: 'auto' }}>
        <Viewer item={item} width={width} height={height} />
      </div>
    </div>
  );
}

const Viewer = ({ item, width, height }) => {
  const threeContainer = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!item) return;

    // Init
    if (threeContainer.current) threeContainer.current.innerHTML = '';

    const camera = new THREE.PerspectiveCamera(70, width / height, 0.01, 10);
    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = 0.8;

    const scene = new THREE.Scene();
    scene.background = null;

    const geometry = new THREE.BoxGeometry(1, 0.7, 0.001);
    //const material = new THREE.MeshNormalMaterial();

    // Mesh
    const loader = new THREE.TextureLoader();
    const mesh = new THREE.Mesh(geometry, [
      new THREE.MeshBasicMaterial({
        map: loader.load(`${assetUrl}/${item.id}/back.png`)
      }),
      new THREE.MeshBasicMaterial({
        map: loader.load(`${assetUrl}/${item.id}/back.png`)
      }),
      new THREE.MeshBasicMaterial({
        map: loader.load(`${assetUrl}/${item.id}/back.png`)
      }),
      new THREE.MeshBasicMaterial({
        map: loader.load(`${assetUrl}/${item.id}/back.png`)
      }),
      new THREE.MeshBasicMaterial({
        map: loader.load(`${assetUrl}/${item.id}/front.png`)
      }),
      new THREE.MeshBasicMaterial({
        map: loader.load(`${assetUrl}/${item.id}/back.png`)
      })
    ]);
    scene.add(mesh);
    mesh.rotation.x = 0;
    mesh.rotation.y = 0;
    mesh.rotation.z = 0;

    // Render
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(width, height);
    renderer.setAnimationLoop(animation);
    renderer.render(scene, camera);
    threeContainer.current.appendChild(renderer.domElement);
    function animation(time) {
      //mesh.rotation.x = time / 100000;
      //mesh.rotation.y = time / 100000;
      renderer.render(scene, camera);
    }

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set(0, 0, 0);
    controls.update();

    setIsLoaded(true);
  }, [height, threeContainer, width, item]);

  return (
    <div>
      {!isLoaded && (
        <div style={{ ...cvStyles.container, width, height }}>
          <div style={{ margin: 'auto' }}>Loading...</div>
        </div>
      )}
      <div
        style={{
          ...cvStyles.threeContainer,
          display: isLoaded ? 'block' : 'none'
        }}
        ref={threeContainer}
      />
    </div>
  );
};

const cvStyles = {
  container: {
    display: 'flex',
    background: 'radial-gradient(#444444, #000000)',
    color: 'white',
    fontWeight: 900
  },
  threeContainer: {
    background: 'radial-gradient(#444444, #000000)'
  }
};

const styles = {
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'black',
    display: 'flex'
  }
};
