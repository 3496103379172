import React from 'react';
import { IconContext } from 'react-icons';
import { TbDownload, TbLayoutSidebarLeftExpand } from 'react-icons/tb';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
const assetUrl = 'https://assets.intrepidpixel.org/twitterpostcards';

const downloadPostcard = async (id) => {
  var zip = new JSZip();
  var count = 0;

  const files = [
    { name: 'front.png', url: `${assetUrl}/${id}/front.png` },
    { name: 'back.png', url: `${assetUrl}/${id}/back.png` },
    { name: 'stamp.png', url: `${assetUrl}/${id}/stamp.png` },
    { name: 'cancellation.png', url: `${assetUrl}/${id}/cancel.png` },
    { name: 'target.png', url: `${assetUrl}/${id}/target.png` }
  ];

  files.forEach((file) => {
    // loading a file and add it in a zip file
    JSZipUtils.getBinaryContent(file.url, function (err, data) {
      if (err) throw err; // or handle the error
      zip.file(file.name, data, { binary: true });
      count++;
      if (count === files.length) {
        zip.generateAsync({ type: 'blob' }).then(async (content) => {
          const url = URL.createObjectURL(content);
          const link = document.createElement('a');
          link.href = url;
          link.download = `postcard-${id}.zip`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    });
  });
};

const CardActions = ({ item, minimized, setMinimized }) => {
  return (
    <div style={styles.container}>
      <IconContext.Provider value={styles.icon}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexGrow: 1 }}>
            {minimized && (
              <TbLayoutSidebarLeftExpand
                onClick={() => setMinimized(!minimized)}
              />
            )}
          </div>
          <div>
            {item?.id && (
              <TbDownload
                onClick={() => {
                  const imageUrl = `${assetUrl}/${item.id}/front.png`;
                  console.log(imageUrl);
                  downloadPostcard(item.id);
                }}
              />
            )}
          </div>
        </div>
      </IconContext.Provider>
    </div>
  );
};
export default CardActions;

const styles = {
  container: {
    position: 'absolute',
    color: 'white',
    margin: 'auto',
    height: '2rem',
    overflow: 'scroll',
    background: 'rgba(0,0,0,0)',
    right: 0,
    top: 0,
    zIndex: 2,
    width: 'calc(100% - 2rem)',
    padding: '1rem'
  },
  header: {
    fontWeight: 900,
    marginTop: '2rem'
  },
  icon: {
    color: 'white',
    size: '2rem'
  }
};
